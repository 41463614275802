import { formatISO } from "date-fns";
import {
  determineRBCEventEndWithEventStart,
  updateToStartOfNextDayIfLastSlot,
} from "../services/commonUsefulFunctions";
import {
  isEventSlotAllDayEvent,
  protectMidnightCarryOver,
} from "./rbcFunctions";
import {
  ISO_DATE_FORMAT,
  SELECT_AVAILABILITY_COLOR,
} from "../services/globalVariables";
import { getCurrentUserEmail } from "./localData";
import { isEmptyArray } from "./arrayFunctions";
import { isFakeMimicEvent } from "./mimicEventUpdate";
import { isTemporaryEvent } from "./eventFunctions";
import { createTemporaryEvent } from "./availabilityFunctions";
import { getUserName } from "./userFunctions";
import { createUUID } from "../services/randomFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";

export const TEMPORARY_EVENT_TYPES = {
  CREATE_EVENT_FIND_TIME: "CREATE_EVENT_FIND_TIME",
  OUTSTANDING_SLOT_EVENT: "OUTSTANDING_SLOT_EVENT",
};

export function doesArrayContainFindTimeEvent(array) {
  if (isEmptyArray(array)) {
    return false;
  }
  return array.some((event) => isFindTimeEventFormEvent(event));
}

export function isFindTimeEventFormEvent(event) {
  return event?.status === TEMPORARY_EVENT_TYPES.CREATE_EVENT_FIND_TIME;
}

export function createEventFormFindTimeTemporaryEvent({
  startTime,
  endTime,
  index,
  currentUserEmail,
}) {
  const timeEnd = updateToStartOfNextDayIfLastSlot(endTime);
  const isAllDayEvent = isEventSlotAllDayEvent({
    eventStart: startTime,
    eventEnd: endTime,
  });
  const status = TEMPORARY_EVENT_TYPES.CREATE_EVENT_FIND_TIME;
  return {
    isTemporary: true,
    isAvailability: true,
    isTemporaryAIEvent: false,
    eventStart: startTime,
    index,
    eventEnd: timeEnd,
    event_end: isAllDayEvent
      ? { date: formatISO(endTime, ISO_DATE_FORMAT) }
      : { dateTime: endTime.toISOString() },
    rbcEventEnd: protectMidnightCarryOver(
      determineRBCEventEndWithEventStart(startTime, timeEnd)
    ),
    backgroundColor: SELECT_AVAILABILITY_COLOR,
    status,
    raw_json: { status },
    id: createUUID(),
    hideCancel: true,
    isGroupVote: false,
    displayAsAllDay: isAllDayEvent,
    resourceId: currentUserEmail || getCurrentUserEmail() || "",
  };
}

export function isFlashingEvent(event) {
  return isTemporaryEvent(event) && !isFakeMimicEvent(event);
}

export function filterOutstandingSlotsByUser(outstandingSlots, user, masterAccount) {
  const { userName: currentUsername } = getUserName({ masterAccount, user });

  return outstandingSlots.filter(outstandingSlot => (
    outstandingSlot.username === currentUsername
  ));
}

export function createOutstandingSlotTemporaryEvent({
  startTime,
  endTime,
  slug,
  title,
  index,
}) {
  const temporaryEvent = createTemporaryEvent({
    startTime,
    endTime,
    index: -1,
    hideCancel: true,
    isTemporaryAIEvent: false,
  });
  temporaryEvent.backgroundColor = undefined;
  temporaryEvent.slotSlug = slug;
  temporaryEvent.status = TEMPORARY_EVENT_TYPES.OUTSTANDING_SLOT_EVENT;
  temporaryEvent.summaryUpdatedWithVisibility = title;
  temporaryEvent.user_event_id = `${slug}-${index}`;
  return temporaryEvent;
}

export function isEventFormTemporaryEvent(event) {
  return event?.isFromEventForm;
}

export function createEventFormTemporaryEvent(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return event;
  }
  return {
    ...event,
    isFromEventForm: true,
  };
}
