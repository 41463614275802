import {
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  formatISO,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getWeek,
  getYear,
  isSameDay,
  isSameMonth,
  isSameWeek,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { ALL_DAY_EVENT_FORMAT } from "../components/scheduling/schedulingSharedVariables";
import { handleError, isInt, isValidJSDate } from "../services/commonUsefulFunctions";
import { DATE_TIME_12_HOUR_FORMAT, DATE_TIME_24_HOUR_FORMAT } from "../services/googleCalendarService";

export function formatISOAllDayDate(date) {
  return formatISO(date, ALL_DAY_EVENT_FORMAT);
}

// create date as utc 0
export function createDateInUTC(jsDate) {
  return new Date(
    Date.UTC(getYear(jsDate), getMonth(jsDate), getDate(jsDate), 0, 0, 0, 0)
  );
}

export function getMondayOfLastCompleteWeek() {
  return subWeeks(getMondayOfDate(new Date()), 1);
}

export function getMondayOfDate(jsDate) {
  return startOfWeek(jsDate, { weekStartsOn: 1 }); // 1 for Monday
}

export function getEndOfWeekMonday(jsDate) {
  return endOfWeek(jsDate, { weekStartsOn: 1 }); // 1 for Monday
}

export function getMondayNumberOfTheYear(jsDate) {
  const mondayOfTheWeek = getMondayOfDate(jsDate);
  return getWeek(mondayOfTheWeek);
}

export function isValidWeekStart(weekStart) {
  // weekStartsOn must be between 0 and 6 inclusively
  //   0: Sunday
  // 1: Monday
  // 2: Tuesday
  // 3: Wednesday
  // 4: Thursday
  // 5: Friday
  // 6: Saturday
  const weekStartsOn = parseInt(weekStart);
  return isInt(weekStartsOn) && weekStartsOn >= 0 && weekStart <= 6;
}

export function isStartOfDay(jsDate) {
  return jsDate.getHours() === 0 && jsDate.getMinutes() === 0;
}

export function areSameHourAndMinute(date1, date2) {
  if (!isValidJSDate(date1) || !isValidJSDate(date2)) {
    return false;
  }
  return (
    getHours(date1) === getHours(date2) &&
    getMinutes(date1) === getMinutes(date2)
  );
}

export function getDateComponents(inputDate) {
  const refDate = inputDate ?? new Date();

  return {
    day: refDate.getDate(),          // Gets the day of the month (1-31)
    month: refDate.getMonth() + 1,   // Gets the month (0-11), +1 to make it 1-12
    year: refDate.getFullYear(),     // Gets the full year (e.g., 2024)
    second: refDate.getSeconds(),    // Gets the seconds (0-59)
    millisecond: refDate.getMilliseconds() // Gets the milliseconds (0-999)
  };
}

export function isLastOccurrenceOfWeekdayInMonth(date) {
  // Get the last day of the month for the given date
  const lastDayOfMonth = endOfMonth(date);
  return differenceInCalendarDays(lastDayOfMonth, date) < 7;
}

export function isSameWeekMonday(date1, date2) {
  // default is for sunday
  try {
    if (!isValidJSDate(date1) || !isValidJSDate(date2)) {
      return false;
    }
    return isSameWeek(date1, date2, { weekStartsOn: 1 }); // check based on monday
  } catch (error) {
    handleError(error);
  }
}

export function getDateTimeFormat(format24HourTime) {
  return format24HourTime ? DATE_TIME_24_HOUR_FORMAT : DATE_TIME_12_HOUR_FORMAT;
}

export function getDateTimeFormatLowercaseAMPM(format24HourTime) {
  return format24HourTime ? DATE_TIME_24_HOUR_FORMAT : "h:mmaaa";
}

export function isSameDayWithErrorCheck(date1, date2) {
  try {
    return isSameDay(date1, date2);
  } catch (error) {
    return false;
  }
}

export function isSameMonthWithErrorCheck(date1, date2) {
  try {
    return isSameMonth(date1, date2);
  } catch (error) {
    return false;
  }
}
